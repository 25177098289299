.cursor-pointer {
  cursor: pointer; }

[data-layout="collapsed-sidebar-1"] .sidebar-heading.sidebar-heading-1 {
  width: 80px;
  margin: 0; }
  [data-layout="collapsed-sidebar-1"] .sidebar-heading.sidebar-heading-1 .sidebar-heading-image {
    min-height: 65px;
    margin: 0 auto 20px auto; }
    [data-layout="collapsed-sidebar-1"] .sidebar-heading.sidebar-heading-1 .sidebar-heading-image .rounded-circle {
      max-width: 60px;
      width: 60px;
      margin: 40px auto 0 -5px; }
    [data-layout="collapsed-sidebar-1"] .sidebar-heading.sidebar-heading-1 .sidebar-heading-image .badge {
      transform: scale(0.65);
      top: 32px;
      left: calc(50%); }
  [data-layout="collapsed-sidebar-1"] .sidebar-heading.sidebar-heading-1 .dropdown {
    display: none; }
  [data-layout="collapsed-sidebar-1"] .sidebar-heading.sidebar-heading-1 .icons {
    display: none; }
