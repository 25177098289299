.spinner-container {
  z-index: 100000000000000000;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.15s;
  opacity: 0;
  visibility: hidden; }
  .spinner-container.show {
    opacity: 1;
    visibility: visible; }
  .spinner-container__img-wrapper {
    width: 100%;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
