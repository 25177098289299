.cursor-pointer {
  cursor: pointer; }

.sidebar-heading-image {
  width: 55px;
  min-height: 55px;
  min-width: 55px;
  position: relative;
  margin: 20px;
  display: block; }
  .sidebar-heading-image .rounded-circle {
    max-width: 50px;
    width: 50px;
    border-style: solid;
    border-width: 2px;
    border-color: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    z-index: 1;
    margin: 0; }
  .sidebar-heading-image .badge {
    position: absolute;
    top: 33px;
    right: 0;
    z-index: 2;
    transform: scale(0.9); }
