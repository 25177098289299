.cursor-pointer {
  cursor: pointer; }

.sidebar-heading.sidebar-heading-1 {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  text-transform: capitalize;
  z-index: 1; }

[data-left-sidebar="light"] .sidebar-heading {
  background-color: #ffffff;
  color: #212121; }

[data-left-sidebar="dark"] .sidebar-heading {
  background-color: #212121;
  color: #f5f5f5; }

[data-left-sidebar="primary"] .sidebar-heading {
  background-color: #303030;
  color: #f5f5f5; }

[data-left-sidebar="secondary"] .sidebar-heading {
  background-color: #8FAD11;
  color: #f5f5f5; }

[data-left-sidebar="info"] .sidebar-heading {
  background-color: #1976d2;
  color: #f5f5f5; }

[data-left-sidebar="success"] .sidebar-heading {
  background-color: #388e3c;
  color: #f5f5f5; }

[data-left-sidebar="warning"] .sidebar-heading {
  background-color: #ffa000;
  color: #f5f5f5; }

[data-left-sidebar="danger"] .sidebar-heading {
  background-color: #d32f2f;
  color: #f5f5f5; }
