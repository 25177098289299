.cursor-pointer {
  cursor: pointer; }

.logo {
  display: flex;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px; }
  .logo i {
    font-size: 28px;
    margin-right: 12px; }
  .logo .title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600; }

[data-navbar="light"] .logo {
  background: #ffffff;
  color: #212121;
  border-bottom: 1px solid #d6d6d6; }

[data-navbar="dark"] .logo {
  background: #212121;
  color: #f5f5f5; }

[data-navbar="primary"] .logo {
  background: #303030;
  color: #f5f5f5; }

[data-navbar="secondary"] .logo {
  background: #8FAD11;
  color: #f5f5f5; }

[data-navbar="info"] .logo {
  background: #1976d2;
  color: #f5f5f5; }

[data-navbar="success"] .logo {
  background: #388e3c;
  color: #f5f5f5; }

[data-navbar="warning"] .logo {
  background: #ffa000;
  color: #f5f5f5; }

[data-navbar="danger"] .logo {
  background: #d32f2f;
  color: #f5f5f5; }
