.cursor-pointer {
  cursor: pointer; }

.jumbotron.jumbotron-1 {
  padding: 20px; }
  .jumbotron.jumbotron-1 h4 {
    font-size: 1.375rem;
    font-weight: 600; }
  .jumbotron.jumbotron-1 h5 {
    font-size: 1rem;
    margin-bottom: 10px; }

[data-background="light"] .jumbotron.jumbotron-1 {
  background-color: #f5f5f5;
  color: #212121; }
