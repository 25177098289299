.cursor-pointer {
  cursor: pointer; }

a,
.btn {
  font-family: "Open Sans", sans-serif; }
  a:hover, a:focus, a:active, a.focus, a.active,
  .btn:hover,
  .btn:focus,
  .btn:active,
  .btn.focus,
  .btn.active {
    cursor: pointer;
    outline: none !important;
    text-decoration: none !important;
    box-shadow: none !important; }

.blockquote.blockquote-light:not(.blockquote-reverse) {
  border-left: 0.25rem solid #f5f5f5 !important; }

.blockquote.blockquote-light.blockquote-reverse {
  border-right: 0.25rem solid #f5f5f5 !important; }

.blockquote.blockquote-dark:not(.blockquote-reverse) {
  border-left: 0.25rem solid #212121 !important; }

.blockquote.blockquote-dark.blockquote-reverse {
  border-right: 0.25rem solid #212121 !important; }

.blockquote.blockquote-default:not(.blockquote-reverse) {
  border-left: 0.25rem solid #212121 !important; }

.blockquote.blockquote-default.blockquote-reverse {
  border-right: 0.25rem solid #212121 !important; }

.blockquote.blockquote-primary:not(.blockquote-reverse) {
  border-left: 0.25rem solid #303030 !important; }

.blockquote.blockquote-primary.blockquote-reverse {
  border-right: 0.25rem solid #303030 !important; }

.blockquote.blockquote-secondary:not(.blockquote-reverse) {
  border-left: 0.25rem solid #8FAD11 !important; }

.blockquote.blockquote-secondary.blockquote-reverse {
  border-right: 0.25rem solid #8FAD11 !important; }

.blockquote.blockquote-info:not(.blockquote-reverse) {
  border-left: 0.25rem solid #1976d2 !important; }

.blockquote.blockquote-info.blockquote-reverse {
  border-right: 0.25rem solid #1976d2 !important; }

.blockquote.blockquote-success:not(.blockquote-reverse) {
  border-left: 0.25rem solid #388e3c !important; }

.blockquote.blockquote-success.blockquote-reverse {
  border-right: 0.25rem solid #388e3c !important; }

.blockquote.blockquote-warning:not(.blockquote-reverse) {
  border-left: 0.25rem solid #ffa000 !important; }

.blockquote.blockquote-warning.blockquote-reverse {
  border-right: 0.25rem solid #ffa000 !important; }

.blockquote.blockquote-danger:not(.blockquote-reverse) {
  border-left: 0.25rem solid #d32f2f !important; }

.blockquote.blockquote-danger.blockquote-reverse {
  border-right: 0.25rem solid #d32f2f !important; }

[data-background="light"] a {
  color: #d32f2f; }

[data-background="light"] pre,
[data-background="light"] code {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  background: #ededed;
  color: #d32f2f; }

[data-background="dark"] a {
  color: #ffa000; }

[data-background="dark"] pre,
[data-background="dark"] code {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  background: #292929;
  color: #1976d2; }

[data-background="primary"] a {
  color: #388e3c; }

[data-background="primary"] pre,
[data-background="primary"] code {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  background: #383838;
  color: #ffa000; }
